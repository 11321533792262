<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Volumetric pipets & flasks, burets, and graduated cylinders all have markings (lines) etched
        into the glassware denoting volume levels. Correctly recording the volumes from these pieces
        of glassware incorporates the following four concepts:
      </p>

      <p class="mb-4">
        <b>Meniscus</b>: The meniscus is the <b>&nbsp;[A]&nbsp;</b> of the liquid surface in the
        glassware. If the liquid contained in the glassware is polar, adhesion is
        <b>&nbsp;[B]&nbsp;</b> cohesion, leading to a <b>&nbsp;[C]&nbsp;</b> meniscus. If the liquid
        is nonpolar, adhesion is <b>&nbsp;[D]&nbsp;</b> cohesion, leading to a
        <b>&nbsp;[E]&nbsp;</b> meniscus.
      </p>

      <p class="mb-4">
        <b>Parallax Error</b>: To avoid parallax error, you must have your eyes level to the
        <b>&nbsp;[F]</b>.
      </p>

      <p class="mb-4">
        <b>Significant Figures</b>: The number of digits used when recording the volume from each
        piece of glassware is determined by the <b>&nbsp;[G]&nbsp;</b> for burets and graduated
        cylinders and by <b>&nbsp;[H]&nbsp;</b> for volumetric pipets & flasks.
      </p>

      <p class="mb-6">
        <b>Temperature</b>: The temperature of the liquid held by glassware
        <b>&nbsp;[I]&nbsp;</b> affect volume measurements, therefore, all liquids should be at
        <b>&nbsp;[J]</b>.
      </p>

      <p class="mb-1">
        [A]:
        <v-select
          v-model="inputs.input1"
          style="display: inline-block; width: 450px"
          class="ml-3"
          :items="items1"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        [B]:
        <v-select
          v-model="inputs.input2"
          style="display: inline-block; width: 450px"
          class="ml-3"
          :items="items2"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        [C]:
        <v-select
          v-model="inputs.input3"
          style="display: inline-block; width: 450px"
          class="ml-3"
          :items="items3"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        [D]:
        <v-select
          v-model="inputs.input4"
          style="display: inline-block; width: 450px"
          class="ml-3"
          :items="items2"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        [E]:
        <v-select
          v-model="inputs.input5"
          style="display: inline-block; width: 450px"
          class="ml-3"
          :items="items3"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        [F]:
        <v-select
          v-model="inputs.input6"
          style="display: inline-block; width: 450px"
          class="ml-3"
          :items="items4"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        [G]:
        <v-select
          v-model="inputs.input7"
          style="display: inline-block; width: 450px"
          class="ml-3"
          :items="items5"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        [H]:
        <v-select
          v-model="inputs.input8"
          style="display: inline-block; width: 450px"
          class="ml-3"
          :items="items5"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        [I]:
        <v-select
          v-model="inputs.input9"
          style="display: inline-block; width: 450px"
          class="ml-3"
          :items="items6"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p>
        [J]:
        <v-select
          v-model="inputs.input10"
          style="display: inline-block; width: 450px"
          class="ml-3"
          :items="items7"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LCQ2S2Q2',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
        input9: null,
        input10: null,
      },
      items1: [
        {text: 'curvature', value: 'curvature'},
        {text: 'level', value: 'level'},
        {text: 'polarity', value: 'polarity'},
        {text: 'tension', value: 'tension'},
      ],
      items2: [
        {text: 'greater than', value: 'greaterThan'},
        {text: 'less than', value: 'lessThan'},
        {text: 'equal to', value: 'equalTo'},
      ],
      items3: [
        {text: 'concave', value: 'concave'},
        {text: 'convex', value: 'convex'},
      ],
      items4: [
        {text: 'top or bottom of the meniscus', value: 'meniscus'},
        {text: 'middle of the glassware', value: 'middle'},
        {text: 'center of your goggles', value: 'center'},
        {text: 'benchtop', value: 'benchtop'},
      ],
      items5: [
        {
          text: 'numbers from markings + a last number estimated between markings',
          value: 'markings',
        },
        {text: "glassware's total volume", value: 'volume'},
        {text: 'etched value on glassware (+/- 0.05 mL)', value: 'etched'},
        {text: 'information provided in the course material', value: 'info'},
      ],
      items6: [
        {text: 'does', value: 'does'},
        {text: 'does not', value: 'doesNot'},
      ],
      items7: [
        {text: 'room temperature', value: 'roomTemp'},
        {text: '0 °C', value: 'zero'},
        {text: '273 K', value: '273'},
        {text: 'the boiling point of the liquid', value: 'boiling'},
      ],
    };
  },
};
</script>
<style scoped></style>
